import { ProductId } from "../types";
import { StockManagerItem } from "./types";

export const MuffinItems: StockManagerItem[] = [
  {
    label: "Blueberry Muffins",
    productId: "muffin-blueberry",
  },
  {
    label: "Double Choc Muffins",
    productId: "muffin-double-choc",
  },
  {
    label: "Raspberry & White Choc Muffin",
    productId: "muffin-raspberry-white-choc-apple",
  },
  {
    label: "Carrot Cake Muffins",
    productId: "muffin-carrot-cake",
  },
];

export const MuffinProductIds: ProductId[] = MuffinItems.map(
  (item) => item.productId
);

export const HotFoodItems: StockManagerItem[] = [
  {
    label: "Classic mince & cheese pie",
    productId: "pie-mince-cheese-classic",
  },
  {
    label: "Classic steak & cheese pie",
    productId: "pie-steak-cheese-classic",
  },
  {
    label: "Classic Potato top mince pie",
    productId: "pie-potato-top-mince-classic",
  },
  {
    label: "Classic mince pie",
    productId: "pie-mince-classic",
  },
  {
    label: "Gourmet butter chicken pie",
    productId: "pie-butter-chicken-gourmet",
  },
  {
    label: "Gourmet bacon & free range egg pie",
    productId: "pie-bacon-egg-gourmet",
  },
  { label: "Gourmet pepper steak pie", productId: "pie-pepper-steak-gourmet" },
  { label: "Gourmet cheeseburger pie", productId: "pie-cheeseburger-gourmet" },
  {
    label: "Sausage roll",
    productId: "roll-sausage",
  },
];

export const HotfoodVegItems: StockManagerItem[] = [
  {
    label: "Gourmet vegan pepper shroom pie",
    productId: "pie-vegan-pepper-shroom-gourmet",
  },
  {
    label: "Gourmet Vegan butt*r chick*n pie",
    productId: "pie-vegan-butter-chicken-gourmet",
  },
  {
    label: "Vegan mince & cheese pie",
    productId: "pie-vegan-mince-cheese",
  },
  {
    label: "Vegan sausage roll",
    productId: "roll-vegan-sausage",
  },
  {
    label: "Vegan chorizo roll",
    productId: "roll-vegan-chorizo",
  },
  {
    label: "Spinach & feta roll",
    productId: "roll-vegan-spinach-feta",
  },
];

export const HotFoodProductIds: ProductId[] = HotFoodItems.map(
  (item) => item.productId
);

export const HotFoodVegProductIds: ProductId[] = HotfoodVegItems.map(
  (item) => item.productId
);

export const ChilledDrinksItems = (veganEnabled: boolean) =>
  veganEnabled
    ? [
        {
          label: "Banana Berry Smoothie",
          productId: "smoothie-berry-breakfast",
        },
        {
          label: "Tropical Smoothie",
          productId: "vegan-smoothie-tropical",
        },
      ]
    : [
        {
          label: "Banana Berry Smoothie",
          productId: "smoothie-berry-breakfast",
        },
      ];

export const FrappeDrinkItems: StockManagerItem[] = [
  {
    label: "Easter Egg Frappé",
    productId: "frappe-easter-egg",
  },
  {
    label: "Banoffee Frappé",
    productId: "frappe-banoffee",
  },
  {
    label: "Matcha Frappé",
    productId: "frappe-matcha",
  },
  {
    label: "Hokey Pokey Frappé",
    productId: "frappe-hokey-pokey",
  },
  {
    label: "Count Frappulla Frappé",
    productId: "frappe-count-frappulla",
  },
];

export const HotDrinkItems: StockManagerItem[] = [
  {
    label: "Hot Cross Bun Latté",
    productId: "hot-cross-bun-latte",
  },
  {
    label: "Spiced Pumpkin Latté",
    productId: "spiced-pumpkin-latte",
  },
  {
    label: "Gingerbread Latté",
    productId: "gingerbread-latte",
  },
  {
    label: "Matcha Latté",
    productId: "matcha-latte",
  },
  {
    label: "Luxury Hot Chocolate",
    productId: "hot-luxury-chocolate",
  },
  {
    label: "Luxury Hot Caramel",
    productId: "hot-luxury-caramel",
  },
];

export const IcedDrinkItems: StockManagerItem[] = [
  {
    label: "Iced Matcha",
    productId: "iced-matcha",
  },
  {
    label: "Iced Latté",
    productId: "iced-latte",
  },
  {
    label: "Iced Mocha",
    productId: "iced-mocha",
  },
  {
    label: "Iced Americano",
    productId: "iced-americano",
  },
  {
    label: "Iced GingerBread",
    productId: "iced-gingerbread-latte",
  },
  {
    label: "Iced Cappuccino",
    productId: "iced-cappuccino",
  },
  {
    label: "Iced Long Macchiato",
    productId: "iced-long-macchiato",
  },
];

export const DrinkIngredientsItems: StockManagerItem[] = [
  {
    label: "Oat Milk",
    productId: "milk-oat",
  },
  {
    label: "Coconut Milk",
    productId: "milk-coconut",
  },
  {
    label: "Soy Milk",
    productId: "milk-soy",
  },
  {
    label: "Almond Milk",
    productId: "milk-almond",
  },
];

export const DrinkIngredientsProductIds: ProductId[] = DrinkIngredientsItems.map(
  (item) => item.productId
);
