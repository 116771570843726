import { createSelector } from "reselect";

import { BaristaState } from "domain/types";

export const getAppState = (state: BaristaState) => state.core.app;

export const getIsAppBootstrapped = createSelector(
  getAppState,
  (state) => state.isBootstrapped
);

export const getHasUpdates = createSelector(
  getAppState,
  (state) => state.hasUpdates
);

export const getIsSiteOnline = createSelector(
  getAppState,
  (state) => state.isSiteOnline
);

export const getIsAlertEnabled = createSelector(
  getAppState,
  (state) => state.isAlertEnabled
);

export const getOnlineStatusChangeReason = createSelector(
  getAppState,
  (state) => state.onlineStatusChangeReason
);

export const getAppSnacks = createSelector(
  getAppState,
  (state) => state.snacks
);

export const getCurrentTabRoute = createSelector(
  getAppState,
  (state) => state.currentTabRoute
);

export const getDisabledCustomisationItems = createSelector(
  getAppState,
  (state) => state.disabledCustomisationItems
);

export const getDisabledCustomisationItemsRequestState = createSelector(
  getAppState,
  (state) => state.requests.disabledCustomisationItems.status
);

export const getDisabledItems = createSelector(
  getAppState,
  (state) => state.disabledItems
);

export const getDisabledItemsRequestState = createSelector(
  getAppState,
  (state) => state.requests.disabledItems.status
);

export const getRemoteConfig = createSelector(
  getAppState,
  (state) => state.remoteConfig
);

export const getVeganEnabledRemoteConfig = createSelector(
  getRemoteConfig,
  (remoteConfig) => remoteConfig?.vegan_smoothies_enabled.value === "true"
);
