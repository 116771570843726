import { SagaIterator } from "redux-saga";
import { spawn, all } from "redux-saga/effects";

export type SagaWatcher = () => SagaIterator;

export function forwardWatchers(watchers: SagaWatcher[]) {
  return function* hubSaga(): SagaIterator {
    yield all(watchers.map(spawn));
  };
}
