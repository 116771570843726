import {
  StatusTransitions,
  ActionableStatus,
  OrderActionConfig,
  SectionConfig,
} from "./types";
import { getDueMinutes, getMinutesSinceStatusUpdated } from "./helpers";

export const ERROR_CODES = {
  INVALID_TRANSITION:
    "urn:zenergy:barista:api:orders:status:invalid-transition",
};

export const ORDERS_POLL_INTERVAL = Number(
  process.env.REACT_APP_ORDERS_POLL_INTERVAL ?? 20000
);

export const ORDERS_FALLBACK_POLL_INTERVAL = Number(
  process.env.REACT_APP_ORDERS_FALLBACK_POLL_INTERVAL ??
    ORDERS_POLL_INTERVAL * 6
);

export const STATUS_TRANSITIONS: StatusTransitions = {
  Paid: [],
  Acknowledged: ["Made"],
  Made: ["PickedUp", "NotPickedUp", "Late"],
  PickedUp: ["Made"],
  NotPickedUp: ["Made"],
  Late: ["Made"],
  Cancelled: [],
};

export const ORDER_ACTIONS: Record<ActionableStatus, OrderActionConfig> = {
  Acknowledged: {
    label: "In queue",
  },
  Made: {
    label: "Ready for Collection",
    shortLabel: "Made",
    variant: "contained",
    color: "primary",
  },
  PickedUp: {
    label: "Picked Up",
    variant: "contained",
    color: "primary",
  },
  NotPickedUp: {
    label: "Not Picked Up",
    variant: "outlined",
    shouldDisplay: (order) => getDueMinutes(order) < 0,
  },
  Late: {
    label: "Picked up late",
    shortLabel: "Late",
    variant: "outlined",
    shouldDisplay: (order) =>
      getDueMinutes(order) < 0 && getMinutesSinceStatusUpdated(order) >= 8,
  },
  Cancelled: {
    label: "Order cancelled",
    shortLabel: "Cancelled",
    variant: "outlined",
    color: "secondary",
    confirm: {
      title: "Are you sure you would like to cancel this order?",
      body: "Once cancelled, an order cannot be moved.",
      actions: {
        cancel: { label: "back" },
        confirm: { label: "cancel order" },
      },
    },
  },
};

export const SECTIONS: SectionConfig[] = [
  {
    heading: "Overdue",
    badgeColor: "secondary",
    criteria: (due: number) => due < 0,
  },
  {
    heading: "Due now",
    criteria: (due: number) => due === 0,
  },
  {
    heading: "Due within 5 min",
    criteria: (due: number) => due > 0 && due <= 5,
  },
  {
    heading: "Due within 10 min",
    criteria: (due: number) => due > 5 && due <= 10,
  },
  {
    heading: "Due after 10 min",
    criteria: (due: number) => due > 10,
  },
];
